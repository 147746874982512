import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { SUB_TAB_GALLERY, WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import { MediaImages } from "./components/media-images";
import Films from "./components/films";
import FloorPlans from "./components/floor-plans";
import galleryApi from "../../apis/api/gallery";
import { useDispatch } from "react-redux";
import { reqGetGalleryList } from "../../reduxs/gallery/action";
import { FLOOR_TYPE, MEDIA_TYPE_ENUM } from "../../constants/master-data";
import socket from "../../helper/socket";
import VirtualTours from "./components/virtual-tours";

const RENDER_ID_ORLA = "6434c906df01b100465efae8";
const FILM_ID_ORLA = "64619a73e03ea7d3fc35b3c2";
const GalleryLanding = ({ isPresentation }) => {
  const [subTabActive, setSubTabActive] = useState(SUB_TAB_GALLERY.IMAGES.key);
  const [floor, setFloor] = useState([]);
  const [imageRender, setImgRender] = useState([]);
  const [filmRender, setFilmRender] = useState([]);
  const [virtualTours, setVirtualTours] = useState([]);
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_SUBTAB_GALLERY_LANDING_PAGE) {
          onClickSubTab(content?.data?.subTabName);
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollPercentage * (pageRef.current.scrollHeight - pageRef.current.clientHeight);
          }
        }
      });
    }
  }, []);
  useEffect(async () => {
    if (subTabActive === SUB_TAB_GALLERY.IMAGES.key) {
      await handleGetImageRender(RENDER_ID_ORLA);
    }
    if (subTabActive === SUB_TAB_GALLERY.FILMS.key) {
      await handleGetFilmRender(MEDIA_TYPE_ENUM.BRICKELL_FILM);
    }
    if (subTabActive === SUB_TAB_GALLERY.VIRTUAL_TOURS.key) {
      await handleGetVirtualTours();
    }
    // if (subTabActive === SUB_TAB_GALLERY.FLOOR_PLANS.key) {
    //   await handleGetListFloor(FLOOR_TYPE.ORLA_FLOOR);
    // }
  }, [subTabActive]);

  const handleGetFilmRender = async (type) => {
    const res = await galleryApi.getListGalleryCMS({
      "type[equal]": type,
    });
    if (res) {
      setFilmRender(res.data);
    }
  };
  const handleGetImageRender = async (galleryId) => {
    const res = await galleryApi.getGalleryDetail(galleryId);
    if (res) {
      setImgRender(res.data);
    }
  };
  const handleGetVirtualTours = async () => {
    const res = await galleryApi.getListGallery({"type[equal]": "virtual_tour"});
    if (res) {
      setVirtualTours(res.data);
    }
  };

  const handleGetListFloor = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };
  const onClickSubTab = (name) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SUBTAB_GALLERY_LANDING_PAGE, {
        subTabName: name
      });
    }
    setSubTabActive(name);
  };
  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  }
  const renderTab = () => {
    return (
      <div className="tab-wrapper">
        <div className="tab-title">Media</div>
        <div className="tab">
          {Object.values(SUB_TAB_GALLERY).map((item) => {
            return (
              <div
                key={`subTab-${item?.key}`}
                className={`pv-btn-tab`}
                onClick={() => onClickSubTab(item.key)}
              >
                <span className="tab-item">{item.key}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderOrla = () => {
    switch (subTabActive) {
      case SUB_TAB_GALLERY.IMAGES.key:
        return <MediaImages data={imageRender} isPresentation={isPresentation} />;
      case SUB_TAB_GALLERY.FILMS.key:
        return <Films data={filmRender} isPresentation={isPresentation} />;
        case SUB_TAB_GALLERY.VIRTUAL_TOURS.key:
          return <VirtualTours isPresentation={isPresentation} virtualTours={virtualTours} />;
      // case SUB_TAB_GALLERY.FLOOR_PLANS.key:
      //   return <FloorPlans data={floor} />;
    }
  };
  const renderContent = () => {
    return <div className="gallery-content">{renderOrla()}</div>;
  };
  return (
    <div className="gallery-landing-relative">
      <div
        className="static-page-wf gallery-landing" ref={pageRef} onScroll={onScroll}
        style={{ backgroundImage: isPresentation ? "none" : undefined, paddingTop: isPresentation ? 0 : 80 }}
      >
        {renderTab()}
        {renderContent()}
      </div>
    </div>
  );
};
export default GalleryLanding;
