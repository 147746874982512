import React, { useEffect } from "react";
import SideNav from "../sidenav";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { PAGES, WEBSOCKET_CHANNEL, ACTION_NAME } from "../../constants/options";
import { reqIsShowUnitDetail } from "../../reduxs/unit-explore/action";
import { setUnitView, setWhereView } from "../../reduxs/scene/action";
import socket from "../../helper/socket";

const UnitDetail = ({ isPresentation  }) => {
  const dispatch = useDispatch();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowUnitDetail = useSelector((state) => state.unitExplore.isShowUnitDetail);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_SEE_VIEWS_UNIT) {
          handleSeeView();
        }
        if (content.action === ACTION_NAME.CLICK_VIEW_FLOORPLAN_UNIT) {
          handleShowFloorplan();
        }
        if (content.action === ACTION_NAME.CLICK_VIEW_GALLERY_UNIT) {
          handleShowGallery();
        }
        if (content.action === ACTION_NAME.CLICK_VIEW_VIRTUAL_TOUR_UNIT) {
          handleShowVirtualTour();
        }
        if (content.action === ACTION_NAME.CLOSE_FLOORPLAN_UNIT) {
          handleCloseFloorplan();
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY_UNIT) {
          handleCloseGallery();
        }
      });
    }
  }, []);

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_VIEW_FLOORPLAN_UNIT);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(true));
    dispatch(reqSetIsTransparent(true));
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FLOORPLAN_UNIT);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_VIEW_GALLERY_UNIT);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
    dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
    dispatch(reqSetIsTransparent(true));
  };

  const handleShowVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_VIEW_VIRTUAL_TOUR_UNIT);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
    dispatch(unitExploreAct.reqIsShowVirtualTour(true));
    dispatch(reqSetIsTransparent(true));
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY_UNIT);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const handleSeeView = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SEE_VIEWS_UNIT);
    }
    dispatch(reqSetPage(PAGES.VIEW_PAGE));
    dispatch(setUnitView(unit?.view));
    dispatch(setWhereView('residence'));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
  };

  const renderBtnSeeView = () => {
    return (
      <li
        onClick={handleSeeView}
        className="list-group-item btn text-uppercase"
      >
        See views
      </li>
    );
  };

  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li
          onClick={() => handleCloseFloorplan()}
          className="list-group-item btn text-uppercase"
        >
          Close Floorplan
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowFloorplan()}
        className="list-group-item btn text-uppercase"
      >
        View Floorplan
      </li>
    );
  };

  const renderBtnGallery = () => {
    if (isShowGallery) {
      return (
        <li
          onClick={() => handleCloseGallery()}
          className="list-group-item btn text-uppercase"
        >
          Close Gallery
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowGallery()}
        className="list-group-item btn text-uppercase"
      >
        View Gallery
      </li>
    );
  };

  const renderBtnVirtualTour = () => {
    if (isShowVirtualTour) {
      return (
        <li
          onClick={() => handleShowVirtualTour()}
          className="list-group-item btn text-uppercase"
        >
          CLOSE VIRTUAL TOUR
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowVirtualTour()}
        className="list-group-item btn text-uppercase"
      >
        VIEW VIRTUAL TOUR
      </li>
    );
  };

  const formatMoney = (price) => {
    if (!price) return 0;

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
    });

    return formatter.format(price);
  };

  return (
    <SideNav
      position="right"
      isDark={false}
      id="sidenavDetail"
      heading={`Residence ${unit?.name ?? ""}`}
    >
      <ul className="list-group">
        <li className="list-group-item">
          <span className="txt-content">
            {unit?.bedrooms ? `${unit.bedrooms} Bedrooms` : null}
            <br />
            {unit?.bathrooms ? `${unit.bathrooms} Bathrooms` : null}
            <br />
            {unit?.powderrooms ? `${unit.powderrooms} Powder Room` : null}
          </span>
        </li>
        {unit?.interiorSF && (
          <li className="list-group-item">
            <span className="txt-content">Interior</span>
            <br />
            <span className="txt-content">
              {`${unit?.interiorSF.toLocaleString()}SF | ${Math.round(
                unit?.interiorSF * 0.092903
              ).toLocaleString()} SQM`}
            </span>
          </li>
        )}
        {unit?.exteriorSF && (
          <li className="list-group-item">
            <span className="txt-content">Exterior</span>
            <br />
            <span className="txt-content">
              {`${unit?.exteriorSF.toLocaleString()}SF | ${Math.round(
                unit?.exteriorSF * 0.092903
              ).toLocaleString()} SQM`}
            </span>
          </li>
        )}
      </ul>

      <div className="wrap-btn-detail mt-auto">
        <ul className="list-group mb-0">
          {renderBtnSeeView()}
          {renderBtnFloorplan()}
          {renderBtnGallery()}
          {unit?.virtualTour && renderBtnVirtualTour()}
        </ul>
      </div>
    </SideNav>
  );
};

export default UnitDetail;
