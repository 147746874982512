import React, {useEffect, useMemo, useState} from "react";
import iconPlay from "../../../assets/images/ellipse.svg";
import "../../home-gallery/index.scss";
import "./index.scss";
import {AwModal} from "../../aw-modal/aw-modal";
import {ACTION_NAME, WEBSOCKET_CHANNEL} from "../../../constants/options";
import socket from "../../../helper/socket";
import {Equirectangular} from "./equirectangular";
import {IconArrowThin} from "../../svgs/icons";
import {getUploadedMediaUrl} from "../../../helper/media";

export const HotspotWrapper = ({medias, setUrl}) => {
  return <ul style={{
    position: 'absolute',
    zIndex: 1000,
    top: '50%',
    transform: 'translate(0%, -50%)',
    left: 0,
    paddingLeft: 35,
    marginBottom: 0
  }}>
    {
      medias?.map((media, i) => (
        <div
          key={i}
          onClick={() => {
            setUrl(media.path)
          }}
          className="hotspot-wrapper"
        >
          {media.name}
        </div>
      ))
    }
  </ul>
}

const VirtualTours = ({isInfinity, isPresentation, virtualTours}) => {
  const [isShowVirtual, setShowVirtual] = useState(false);
  const [urls, setUrls] = useState([]);
  const [name, setName] = useState("");
  const [urlVirtual, setUrlVirtual] = useState("");

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.PLAY_FILM_GALLERY_PAGE) {
          handleShowVirtual(content?.data?.i);
        }
        if (content.action === ACTION_NAME.STOP_FILM_GALLERY_PAGE) {
          onStopVirtual();
        }
        if (content.action === ACTION_NAME.SWITCH_HOTSPOT) {
          setUrl(content?.data?.url);
        }
      });
    }
  }, []);
  const handleShowVirtual = (i) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_FILM_GALLERY_PAGE, {
        i,
      });
    }
    setName(i.name)
    setUrls(i.media);
    setUrlVirtual(i.media[0].path);
    setShowVirtual(true);
  };
  const onStopVirtual = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_FILM_GALLERY_PAGE);
    }
    setShowVirtual(false);
    setUrlVirtual("");
  };

  const setUrl = (url) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SWITCH_HOTSPOT, {
        url
      });
    }
    setUrlVirtual(url);
  }

  return (
    <div className="films-content">
      {virtualTours.map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity ? "infinity" : ""}`}
            style={{
              backgroundImage: `url(${getUploadedMediaUrl(i.media[0].path)}`,
              backgroundPosition: 'center'
            }}
            onClick={() => {
              handleShowVirtual(i);
            }}
          >
            <div className="card-films-title">{`${i.name}`}</div>
            <div className="card-films-sub">{i.description}</div>
            <img src={iconPlay} alt="play-icon"/>
          </div>
        )
      })}
      {isShowVirtual && (
        // <AwModal show={isShowVirtual} onClose={onStopVirtual}>
        //     <HotspotWrapper setUrl={setUrlVirtual} urls={urls}/>
        //     <Equirectangular url={urlVirtual}/>
        // </AwModal>
        <div className="wrap-floor-plan-gallery">
          <AwModal show={isShowVirtual}>
            <div className="wrap-nav" style={{top: isPresentation ? -80 : 0}}>
              <p className="unit-name">{name}</p>
              <p className="close-button" onClick={onStopVirtual}>
                <IconArrowThin style={{width: 31, marginRight: 4}} fill={"#B2B2B2"}/>
                CLOSE VIRTUAL TOUR
              </p>
            </div>
            <HotspotWrapper setUrl={setUrl} isPresentation={isPresentation} medias={urls}/>
            <Equirectangular url={urlVirtual} isPresentation={isPresentation}/>
            {/* <span className="close-button-down" style={{ bottom: isPresentation ? 20 : 100}} onClick={onStopVirtual}>CLOSE VIRTUAL TOUR</span> */}
          </AwModal>
        </div>
      )}
    </div>
  );
};
export default VirtualTours;
